<template>
  <v-card
    class="search-container"
    :class="{
      'is-show-result': !!searchResult,
      'is-mobile': isMobile,
    }"
    :tile="!!searchResult"
    :flat="!searchResult"
    :color="!searchResult ? 'transparent' : ''"
  >
    <SimpleSearchInput
      @click:clear="onResetSearch()"
      @click:select="onSelectSearch"
      @click:complex-search="onComplexSearch()"
      :loading.sync="c_loading"
      :show-select-complex="!searchResult"
      v-if="!isComplex"
      v-model="params.search"
    >
      <template
        #extra-search
        v-if="!isMobile && itemsSearch && itemsSearch.length > 0"
      >
        <v-divider></v-divider>
        <a
          class="px-4 py-2 text-caption d-block"
          @click="onBackToComplexSearch()"
        >
          Quay lại kết quả
        </a>
      </template>
      <v-card
        flat
        tile
        class="full-height d-flex flex-columnn"
        v-if="!isMobile && searchResult"
      >
        <SearchResult :value="searchResult" :loading.sync="c_loading" />
      </v-card>
    </SimpleSearchInput>
    <template v-if="isMobile">
      <ComplexMobileSearchInput
        v-show="isComplex"
        :search.sync="params.search"
        :searchResult="searchResult"
        @click:reset="onResetSearch()"
        @click:select="onSelectSearch"
        :loading.sync="c_loading"
        ref="complexSearch"
        :isSearch.sync="c_isSearch"
        :items.sync="itemsSearch"
      />
      <div
        class="mobile-mini-search"
        v-if="!c_isSearch && itemsSearch.length > 0"
      >
        <div class="pr-3 d-flex text-caption">
          <v-spacer></v-spacer>
          <v-btn
            @click="onBackToComplexSearchList()"
            btn
            fab
            depressed
            small
            color="white"
            light
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
        <SearchCarouselGroup
          simple
          class="px-3"
          :items="itemsSearchShow"
          @click:select="onSelectSearch"
        />
      </div>
    </template>
    <ComplexSearchInput
      v-show="isComplex"
      v-else
      :search.sync="params.search"
      @click:reset="onResetSearch()"
      @click:select="onSelectSearch"
      :loading.sync="c_loading"
      ref="complexSearch"
      :items.sync="itemsSearch"
    />

    <BottomResult
      v-if="isMobile"
      :show="!!searchResult"
      @click:reset="onResetSearch()"
      :showBack="itemsSearch && itemsSearch.length > 0"
      @click:back="onBackToComplexSearch()"
    >
      <template #default>
        <SearchMobileResult
          v-if="searchResult"
          :value="searchResult"
          :loading.sync="c_loading"
          fullscreen
        />
      </template>
    </BottomResult>
  </v-card>
</template>

<script>
export default {
  components: {
    SearchCarouselGroup: () =>
      import(
        /* webpackChunkName: "mobileapp" */ "@/views/Result/SearchCarouselGroup.vue"
      ),
    SearchResult: () =>
      import(
        /* webpackChunkName: "fullapp" */ "@/views/Result/SearchResult.vue"
      ),
    SearchMobileResult: () =>
      import(
        /* webpackChunkName: "mobileapp" */ "@/views/Result/SearchMobileResult.vue"
      ),
    BottomResult: () =>
      import(/* webpackChunkName: "mobileapp" */ "@/views/BottomResult.vue"),
    SimpleSearchInput: () => import("./Search/SimpleSearchInput.vue"),
    ComplexSearchInput: () => import("./Search/ComplexSearchInput.vue"),
    ComplexMobileSearchInput: () =>
      import("./Search/ComplexMobileSearchInput.vue"),
  },
  props: {
    searchResult: { type: Object },
    loading: Boolean,
    isSearch: Boolean,
  },
  data: () => ({
    itemsSearch: [],
  }),
  watch: {
    searchResult: {
      handler(value) {
        if (value) this.isComplex = false;
      },
      immediate: true,
    },
  },
  computed: {
    itemsSearchShow() {
      let items = this.$store.getters["map/itemSearch"](
        this.itemsSearch.map((x) => `${x.placeTypeId}-${x.id}`)
      );
      return items;
    },
    isComplex: {
      get() {
        return this.$store.getters["search/isComplex"];
      },
      set(params) {
        this.$store.commit("search/setIsComplex", params);
      },
    },
    params: {
      get() {
        return this.$store.getters["search/params"];
      },
      set(params) {
        this.$store.commit("search/setParams", params);
      },
    },
    isCanSearch() {
      return !this.searchResult || this.searchResult.name != this.params.search;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    c_loading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$emit("update:loading", value);
      },
    },
    c_isSearch: {
      get() {
        return this.isSearch;
      },
      set(value) {
        this.$emit("update:isSearch", value);
      },
    },
    isEmptySearch() {
      return Object.keys(this.params).every((key) => !this.params[key]);
    },
  },
  created() {},
  methods: {
    onSelectSearch(e) {
      this.c_isSearch = false;

      this.$emit("update:searchResult", e);
    },
    onResetSearch() {
      this.itemsSearch = [];
      this.c_isSearch = false;
      this.isComplex = false;
      this.params.search = "";
      this.$emit("click:reset");
      if (!this.isMobile) this.onSimpleSearch();
    },
    onSimpleSearch() {
      this.isComplex = false;
      this.c_isSearch = false;
    },
    onComplexSearch(focusSearch = false) {
      this.$emit("clear:searchResult");
      this.$nextTick(() => {
        this.isComplex = true;
        this.c_isSearch = true;
        this.$nextTick(() => {
          if (
            focusSearch ||
            ((!this.itemsSearch || this.itemsSearch.length < 1) &&
              this.$refs.complexSearch)
          )
            this.$refs.complexSearch.fetchData();
        });
      });
    },
    getItemSearch() {
      return this.$refs.complexSearch.getItemSearch();
    },
    onBackToComplexSearchList() {
      // for mobile
      this.$emit("clear:searchResult");
      this.isComplex = true;
      this.c_isSearch = true;
    },
    onBackToComplexSearch() {
      this.$emit("clear:searchResult");
      this.isComplex = true;
      if (!this.isMobile) this.c_isSearch = true;
      this.$refs.complexSearch.onUpdateItemSearchInLayer();
    },
  },
};
</script>

<style scoped>
.is-mobile {
  width: 100%;
}
.search-container:not(.is-show-result) {
  box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
}
.mobile-mini-search {
  position: fixed;
  bottom: 15px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 180px;
}
.btn-back {
  color: white;
  text-shadow: 1px 1px 0 #00000099, -1px -1px 0 #00000099, 1px -1px 0 #00000099,
    -1px 1px 0 #00000099, 1px 1px 0 #00000099;
}
</style>
