<template>
  <v-slide-group
    active-class="success"
    show-arrows
    center-active
    v-if="items.length > 0"
    v-model="silderItem"
  >
    <template #next>
      <v-btn fab depressed small color="white" light>
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </template>
    <template #prev>
      <v-btn fab depressed small color="white" light>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </template>
    <v-slide-item
      v-for="item in items"
      :key="item.id"
      v-slot="{ active, toggle }"
    >
      <v-card
        :class="{ 'active-class': active }"
        class="ma-2 overflow-hidden silde-card"
        width="150px"
        height="265px"
        :flat="!active"
        :outlined="!active"
        @click="
          toggle($event);
          $emit('click:select', item);
        "
        :title="item.name + '\n' + item.address"
        @mouseover="onHoverItem(item)"
        @mouseleave="onHoverItem()"
      >
        <v-img
          :src="item.background || '/images/default-picture-3.png'"
          height="45%"
        ></v-img>
        <v-card-title
          class="py-2 full-width font-weight-regular text-subtitle-1"
        >
          <div class="pb-2" style="line-height: 1.5rem">
            {{ trimEllip(item.address || item.name, 25) }}
          </div>
        </v-card-title>
        <v-card-subtitle>
          <div class="ml-n1 d-flex justify-space-between">
            <div>
              <Rating :value="item.rating" simple></Rating>
            </div>
            <div class="text-caption pl-2">
              <StatusTypeText :value="!item.isClosed" />
            </div>
          </div>
          <template v-if="item.place"> {{ item.place.name }}</template>
          <guide-link-button
            v-if="item.guideLink"
            :link="item.guideLink"
            class="text-decoration-none d-block pt-3 pr-2"
          >
            <v-btn color="primary" class="text-none" small rounded depressed>
              <v-icon left> mdi-directions </v-icon>
              {{ $t("map.SearchControl.Guide.Title") }}
            </v-btn>
          </guide-link-button>
        </v-card-subtitle>
      </v-card>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import StatusTypeText from "@/views/StatusTypeText.vue";
import { debounce } from "@/utils/Debounce";
import Rating from "@components/rating.vue";
export default {
  components: { StatusTypeText, Rating },
  props: {
    simple: Boolean,
    items: { type: Array, default: () => [] },
    currentItem: { type: Object },
  },
  data: () => ({ silderItem: null }),
  computed: {},
  watch: {
    currentItem: {
      handler(value) {
        if (!value) {
          return;
        }
        this.silderItem = this.items.findIndex((x) => x.id == value.id);
      },
      immediate: true,
    },
  },
  methods: {
    trimEllip(value, length) {
      return value.length > length ? value.substring(0, length) + "..." : value;
    },
    onHoverItem: debounce(function (item) {
      this.$root.$emit(
        "hover:complex-search-items",
        [item, this.currentItem].reduce((acc, cur) => {
          if (cur && cur.lng && cur.lat) {
            acc.push([cur.lng, cur.lat]);
          }
          return acc;
        }, [])
      );
    }, 200),
  },
};
</script>

<style scoped>
.silde-card {
  border: thin solid rgba(0, 0, 0, 0.24);
}
.active-class {
  border: solid 2px var(--v-primary-base);
}
.silde-card:hover {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}
.text-truncate-2 {
  display: -webkit-box;
  /* max-width: 200px; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5rem;
  margin-bottom: 8px;
}
</style>
