<template>
  <v-card
    flat
    tile
    class="full-height d-flex flex-column"
    :class="{ 'overflow-hidden': !fullscreen, 'overflow-auto': fullscreen }"
  >
    <v-img
      :src="value.background || '/images/default-picture-1.png'"
      class="image-search-container flex-grow-0 flex-shrink-1"
      min-height="150px"
    ></v-img>
    <SearchPlaceResult
      class="flex-grow-1"
      :class="{ 'overflow-auto': !fullscreen }"
      style="min-height: 150px"
      :value="value"
      :loading.sync="c_loading"
      :simple="simple"
    />
  </v-card>
</template>

<script>
import SearchPlaceResult from "./SearchPlaceResult.vue";
export default {
  components: { SearchPlaceResult },
  props: {
    value: { type: Object },
    loading: Boolean,
    simple: Boolean,
    fullscreen: Boolean,
  },
  data: () => ({}),
  computed: {
    c_loading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$emit("update:loading", value);
      },
    },
  },
  methods: {},
};
</script>

<style scoped></style>
